<template>
  <div class="historia-clinica">
    <DataTable
      :value="history" :paginator="true" :rows="10"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[10,20,50]"
      responsiveLayout="scroll"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      showGridlines
    >
      <template #header>
        <div class="p-grid">
          <label class="p-mx-3 my-auto">
            N° documento
          </label>
          <span class="p-input-icon-left p-float-label py-2">
            <i class="pi pi-search" />
            <InputText
              type="text"
              v-model="filters.numDcto"
              placeholder="buscar"
              class="p-inputtext-sm"
            />
        </span>
          <Button
            class="p-button-sm p-ml-3 p-mt-auto my-auto"
            label="Buscar"
            icon="pi pi-search"
            iconPos="right"
            @click="searchHistory"
          />
          <Button
            class="p-button-sm p-ml-3 p-button-danger p-mt-auto my-auto"
            label="Limpiar"
            icon="pi pi-trash"
            iconPos="right"
            @click="clearTable"
          />
          <Button
            class="p-button-sm p-ml-3 p-button-success p-mt-auto my-auto"
            label="Exportar a Excel"
            icon="pi pi-file-excel"
            iconPos="right"
            @click="descargarExcel"
          />
        </div>
        <div class="p-grid p-mt-3">
          <span class="p-mx-3">
            Nombre paciente:
          </span>
          <span>
            {{ nameUser }}
          </span>
        </div>
      </template>
      <Column header="No.OV">
        <template #body="{ data }">
          {{ data['No.OV'] }}
        </template>
      </Column>
      <Column header="No.Autoriza">
        <template #body="{ data }">
          {{ data['No.Autoriza'] }}
        </template>
      </Column>
      <Column header="FecDcto">
        <template #body="{ data }">
          {{ $h.formatDate(data['FecConta'],'YYYY/MM/DD')  }}
        </template>
      </Column>
<!--      <column header="Cliente">
        <template #body="{ data }">
          {{data['Cliente']}}
        </template>
      </column>-->
      <column header="Tip.Autoriza">
        <template #body="{ data }">
          {{data['Tip.Autoriza']}}
        </template>
      </column>
      <column header="Rango/Nivel">
        <template #body="{ data }">
          {{data['Rango/Nivel']}}
        </template>
      </column>
      <column header="Dpt/Ciudad">
        <template #body="{ data }">
          {{data['U_ACS_Dpto']}}/{{data['U_ACS_Ciudad']}}
        </template>
      </column>
      <column header="Cod.Mx">
        <template #body="{ data }">
          {{data['Cod.Mx']}}
        </template>
      </column>
      <column header="Dscription">
        <template #body="{ data }">
          {{data['Dscription']}}
        </template>
      </column>
      <column header="Cant.Ent">
        <template #body="{ data }">
          {{parseInt(data['Cant.Ov'])}}
        </template>
      </column>
      <column header="Seg. Entrega">
        <template #body="{ data }">
          {{data['SeguiEntrega']}}
        </template>
      </column>
      <!-- <column header="Fecha Entrega">
        <template #body="{ data }">
          {{ $h.formatDate(data['FechaEntrega'],'YYYY/MM/DD')  }}
        </template>
      </column> -->
    </DataTable>
  </div>
</template>
<script>
import { ref } from 'vue'
import { apiHistoriaClinicaSaliudMia } from '../ejecucion/services'
import orderBy from 'lodash/orderBy'
import { messageWarning } from '../../../../../libs/mensajes'
import axios from 'axios'
export default {
  name: 'HistoriaClinica',
  setup (props) {
    const history = ref([])
    const search = ref('')
    const filters = ref({
      numDcto: null,
      client: 'SALUDMIA'
    })
    const getHistory = async (params) => {
      const { data } = await apiHistoriaClinicaSaliudMia(params)
      history.value = []
      nameUser.value = ''
      console.log('Data: ', data.result.length)
      if (data.result.length) {
        history.value = orderBy(data.result, ['No.OV'], ['desc'])
        nameUser.value = data.result[0]['Nom.Paciente']
      } else {
        messageWarning('No existe el paciente con identificacion ' + filters.value.numDcto + ' o el paciente no pertenece al cliente ' + filters.value.client)
        return false
      }
    }
    const searchHistory = () => {
        getHistory(filters.value)
    }
    const clearTable = () => {
      search.value = ''
      history.value = []
      nameUser.value = ''
    }
    const nameUser = ref('')

    const descargarExcel = () => {
      console.log(search.value)
      if (!filters.value.numDcto) {
        messageWarning('Ingrese un número de documento')
        return false
      } else {
        const url = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}/execution-report-cliente/historia-clinica-export`
        const res = axios.getUri({ url, params: { ...filters.value } })
        window.open(res, '_blank')
      }
    }
    return {
      filters,
      history,
      search,
      searchHistory,
      clearTable,
      nameUser,
      descargarExcel
    }
  }
}
</script>
<style lang="scss">
  .historia-clinica{
    .p-component{
      font-size: 0.7rem;
    }
  }
</style>
