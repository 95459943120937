import http from '@/libs/http'

const baseUrl = process.env.VUE_APP_MICROSERVICE_API_DASHBOARD

export default class MedicamentosService {
  apiGetBodegasSaludMia () {
    return http.get(`${baseUrl}/listar-bodegas-saludMia`, {
      headers: {
        loading: true
      }
    })
  }
}
