<script setup>
import { computed, onMounted, ref } from 'vue'
import dayjs from 'dayjs'
import axios from 'axios'
import { warning } from '../../../../../libs/mensajes'
import HistoriaCLinica from './historiaClinica'
import { useStore } from 'vuex'
import Services from './service'

const _Services = ref(new Services())
const store = useStore()
const disabledMonth = ref(false)
const filters = ref({
  cliente: '',
  year: '',
  mes: ''
})
const Bodegas = ref([])
const ListarBodegas = ref([])

const nameClient = computed(() => {
  return store.getters['auth/getPeople']
})

const months = ref([
  { id: 1, name: 'Enero' },
  { id: 2, name: 'Febrero' },
  { id: 3, name: 'Marzo' },
  { id: 4, name: 'Abril' },
  { id: 5, name: 'Mayo' },
  { id: 6, name: 'Junio' },
  { id: 7, name: 'Julio' },
  { id: 8, name: 'Agosto' },
  { id: 9, name: 'Septiembre' },
  { id: 10, name: 'Octubre' },
  { id: 11, name: 'Noviembre' },
  { id: 12, name: 'Diciembre' }
])

const years = ref([
  2026, 2025, 2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017
])

// Desactivar el select de mes si no se ha seleccionado un año
const handleYearChange = () => {
  if (filters.value.year === '') {
    filters.value.mes = ''
    disabledMonth.value = true
  } else {
    disabledMonth.value = false
  }
}

const listarBodegasSalidMia = () => {
  _Services.value.apiGetBodegasSaludMia().then(({ data }) => {
    if (data) ListarBodegas.value = data
  })
}

// Computada para mostrar los años del 2017 al actual
const filterYears = computed(() => {
  return years.value.filter((itemYear) => {
    return itemYear <= dayjs().year()
  })
})

// Exportar a excel
const handleExport = () => {
  const url = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}/export-excel-reporte-1`
  const res = axios.getUri({ url, params: filters.value })
  if (filters.value.cliente !== '' && filters.value.year !== '' && filters.value.mes !== '') {
    window.open(res, '_blank')
  } else {
    warning('Debe seleccionar año y mes')
  }
}

const ExportSaludMia = () => {
  const url = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}/export-stock-SaludMia`
  const params = JSON.stringify({ bodegas: Bodegas.value })
  const res = axios.getUri({ url, params })
  if (Bodegas.value.length) {
    window.open(res, '_blank')
  } else {
    warning('Debe seleccionar al menos una bodega')
  }
}

// onMOunted
onMounted(() => {
  listarBodegasSalidMia()
  filters.value.cliente = nameClient.value.firt_name

  disabledMonth.value = true
})
</script>

<template>

  <div class="col-span-12 lg:col-span-6 my-4">
    <h2 class="text-lg font-medium truncate mr-5 capitalize">Reportes</h2>
  </div>

  <div class="grid grid-cols-12 gap-6 mt-5" >

    <section class="col-span-12 sm:col-span-6 xl:col-span-6">

      <div v-if="$can('pharmasan.dashboard.reportes.card-informe-entregas')" class=" box p-5">
        <h2 class="text-base font-semibold mb-4">Informe de Entregas</h2>

        <!-- Filtros -->
        <div class="grid grid-cols-12 gap-x-4 gap-y-2">
          <div class="col-span-6 sm:col-span-4">
            <label for="start_year" class="block text-sm font-medium text-gray-700">Año:</label>
            <select class="form-select form-select-sm mt-1" id="start_year" v-model="filters.year" @change="handleYearChange">
              <option value="">- Seleccione una opción -</option>
              <option v-for="(row,k) in filterYears" :key="k" :value="row">{{ row }}</option>
            </select>
          </div>
          <div class="col-span-6 sm:col-span-4">
            <label for="start_month" class="block text-sm font-medium text-gray-700">Mes:</label>
            <select class="form-select form-select-sm mt-1" id="start_month" v-model="filters.mes" :disabled="disabledMonth">
              <option value="">- Seleccione una opción -</option>
              <option v-for="(row,k) in months" :key="k" :value="row.id">{{ row.name }}</option>
            </select>
          </div>
          <div class="col-span-12 sm:col-span-4">
            <label for="start_month" class="block text-sm font-medium text-gray-700">&nbsp;</label>
            <div class="flex w-full">
              <Button
                label="Exportar"
                icon="pi pi-file-excel"
                class="p-button-xs mt-1 h-7 w-full sm:mr-2"
                severity="success"
                @click="handleExport"
              />
            </div>
          </div>
        </div>

      </div>

    </section>
    <section class="col-span-12 sm:col-span-6 xl:col-span-6">

      <div v-if="$can('pharmasan.dashboard.reportes.card-stock-bodegas')" class=" box p-5">
        <h2 class="text-base font-semibold mb-4">Stock Bodegas</h2>

        <!-- Filtros -->
        <div class="grid grid-cols-12 gap-x-4 gap-y-2">
          <div class="col-span-6 sm:col-span-4">
            <label for="start_month" class="block text-sm font-medium text-gray-700">Bodegas:</label>
            <!-- <Dropdown v-model="Bodegas" optionLabel="nombod" optionValue="codbod" :options="ListarBodegas" placeholder="Seleccione" class="w-full"/> -->
            <MultiSelect v-model="Bodegas" :options="ListarBodegas" optionLabel="nombod" optionValue="codbod" placeholder="Seleccione"
              class="multiselect-custom p-multiselect-label2 rounded-md border-gray-300 h-8 mt-1 w-full p-inputtext-xs text-xs" :maxSelectedLabels="3"
              />
            <!-- <select class="form-select form-select-sm mt-1" id="bodegas" v-model="Bodegas" multiple>
              <option value="">- Seleccione una opción -</option>
              <option v-for="(row,k) in ListarBodegas" :key="k" :value="row.codbod">{{ row.nombod }}</option>
            </select> -->
          </div>
          <div class="col-span-12 sm:col-span-4">
            <label for="start_month" class="block text-sm font-medium text-gray-700">&nbsp;</label>
            <div class="flex w-full">
              <Button
                label="Exportar"
                icon="pi pi-file-excel"
                class="p-button-xs mt-1 h-7 w-full sm:mr-2"
                severity="success"
                @click="ExportSaludMia"
              />
            </div>
          </div>
        </div>

      </div>

    </section>
    <section class="col-span-12">
      <div v-if="$can('pharmasan.dashboard.reportes.card-buscar-paciente')" class=" box p-5">
        <h2 class="text-base font-semibold mb-4">Consultar paciente</h2>
        <!-- Filtros -->
        <div class="gap-x-4 gap-y-2">
          <HistoriaCLinica></HistoriaCLinica>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.p-multiselect-label2 {
  ::v-deep(.p-multiselect .p-multiselect-label) {
    padding: 0.5rem 0.75rem!important;
  }
}
</style>
